import React, { useState, useEffect } from "react";
import styles from '../../Styles/Dropdown.module.css';
import { apiCall } from "../../utils/apiCall";

function Test({ value, onChange }) {
  const [testTypeOptions, setTestOptions] = useState([]);

  useEffect(() => {
    const fetchTestOptions = async () => {
      try {
        const response = await apiCall('testTypes','GET');
        setTestOptions(response.testTypes);
      } catch (error) {
        console.error('Error fetching testTypes options:', error);
      }
    };

    fetchTestOptions();
  }, []);

  return (
    <select className={styles.regfields} value={value} onChange={onChange}>
      <option value="">Select Test Type</option>
      {testTypeOptions.map((option, index) => (
        <option key={index} className={styles.class} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Test;