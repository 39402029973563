import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, ...props }) => {
    const token = localStorage.getItem('usertoken');

    if (!token) {
        alert("Please log in to continue.");
        return <Navigate to="/Login" />;
    }

    return React.cloneElement(element, props);
};

export default PrivateRoute;
