import React, { useState } from "react";
import styles from '../../Styles/Form.module.css';
import { RiUserFill, RiMailFill } from 'react-icons/ri';
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Form({
    onSignIn,
    isLoginForm,
    showFullName = true,
    showEmail = true,
    showPassword = true,
    showConfirmPassword = true,
    fullNameIcon = <RiUserFill className={styles.icon} />,
    emailIcon = <RiMailFill className={styles.icon} />,
}) {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [createpassword, setCreatePassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [show_password, setShowPassword] = useState(false);
    const [show_confirm_password, setShowConfirmPassword] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "fullName":
                setFullName(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "createpassword":
                setCreatePassword(value);
                break;
            case "confirmpassword":
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!show_password);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!show_confirm_password);
    };

    const handleSignIn = () => {
        if (onSignIn) {
            onSignIn({
                fullName,
                email,
                createpassword,
                confirmpassword,
            });
        }
    };

    return (
        <div className={styles.main}>
            <div className={styles.form_box}>
                {showFullName && (
                    <div className={styles.input_wrapper}>
                        <input
                            type="text"
                            name="fullName"
                            value={fullName}
                            className={styles.fields}
                            placeholder="Full Name"
                            onChange={handleInputChange}
                        />
                        {fullNameIcon}
                    </div>
                )}
                {showEmail && (
                    <div className={styles.input_wrapper}>
                        <input
                            type="text"
                            name="email"
                            value={email}
                            className={styles.fields}
                            placeholder="Email"
                            onChange={handleInputChange}
                        />
                        {emailIcon}
                    </div>
                )}
                {showPassword && (
                    <div className={styles.input_wrapper}>
                        <input
                            type={show_password ? "text" : "password"}
                            name="createpassword"
                            value={createpassword}
                            className={styles.fields}
                            placeholder={isLoginForm ? "Password" : "Create Password"}
                            onChange={handleInputChange}
                        />
                        {show_password ? (
                            <FaEyeSlash className={styles.icon} onClick={togglePasswordVisibility} />
                        ) : (
                            <FaEye className={styles.icon} onClick={togglePasswordVisibility} />
                        )}
                    </div>
                )}
                {showConfirmPassword && (
                    <div className={styles.input_wrapper}>
                        <input
                            type={show_confirm_password ? "text" : "password"}
                            name="confirmpassword"
                            value={confirmpassword}
                            className={styles.fields}
                            placeholder="Confirm Password"
                            onChange={handleInputChange}
                        />
                        {show_confirm_password ? (
                            <FaEyeSlash className={styles.icon} onClick={toggleConfirmPasswordVisibility} />
                        ) : (
                            <FaEye className={styles.icon} onClick={toggleConfirmPasswordVisibility} />
                        )}
                    </div>
                )}
                <button className={styles.button} onClick={handleSignIn}>
                    {isLoginForm ? "LogIn" : "SignUp"}
                </button>
            </div>
        </div>
    );
}

export default Form;
