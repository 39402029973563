import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import styles from '../../Styles/LectureViewModal.module.css'; 

function LectureViewModal({ show, onClose, lectureData }) {
    if (!show || !lectureData) return null;

    const { binfile } = lectureData;
    const { chapters } = binfile || {};
    const chapter = chapters ? chapters[0] : {}; 

    const handleWatchLecture = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2 className={styles.modalTitle}>Lecture Details</h2>
                <button className={styles.closeButton} onClick={onClose}>X</button>

                <div className={styles.lectureDetails}>
                    <h3>{chapter.chapter_name}</h3>
                    <div className={styles.lectureList}>
                        {chapter.lecture.map((lecture, index) => (
                            <div key={index} className={styles.lectureItem}>
                                <p><strong>Title:</strong> {lecture.title}</p>
                                <p><strong>Channel:</strong> {lecture.channel}</p>
                                <p><strong>Views:</strong> {lecture.views.toLocaleString()}</p>
                                <button 
                                    onClick={() => handleWatchLecture(lecture.url)} 
                                    className={styles.lectureButton}
                                >
                                    Watch Lecture <IoIosArrowForward />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LectureViewModal;
