import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoutsAdmin = ({ element, ...props }) => {
    const admintoken = localStorage.getItem('admintoken');

    if (!admintoken) {
        alert("Please log in to continue.");
        return <Navigate to="/Adminlogin" />;
    }

    return React.cloneElement(element, props);
};

export default PrivateRoutsAdmin;
