import React from "react";
import styles from '../Styles/About.module.css';
import Navbar from "../components/common/Navbar";

function About() {
    return (
        <div className={styles.container}>
            <Navbar />
            <main className={styles.main}>
                <div className={styles.header}>
                    <h1 className={styles.title}>About Our Mission</h1>
                    <div className={styles.underline}></div>
                </div>

                <div className={styles.contentWrapper}>
                    <section className={styles.section}>
                        <h2 className={styles.sectionTitle}>Our Vision</h2>
                        <div className={styles.text}>
                            While West Bengal, India boasts several commendable educational institutions, they often fall short in addressing the unique needs of aspiring young underprivileged students. Conventional schools may overlook the incorporation of religious teachings, leaving young scholars disconnected from their faith. Alif-Lam-Mim emerges as a pioneering effort, aiming to blend contemporary scientific education with spiritual enrichment seamlessly.
                        </div>
                    </section>

                    <section className={styles.section}>
                        <h2 className={styles.sectionTitle}>The Challenge</h2>
                        <div className={styles.statsContainer}>
                            <div className={styles.statBox}>
                                <span className={styles.statNumber}>5 mile</span>
                                <span className={styles.statLabel}>Radius of Impact</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={styles.statNumber}>30+</span>
                                <span className={styles.statLabel}>Communities Served</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={styles.statNumber}>90%</span>
                                <span className={styles.statLabel}>Students Show High Potential</span>
                            </div>
                        </div>
                        <div className={styles.text}>
                            Within the proximate 5-mile radius of Kanachi, an enclave home to roughly 20 to 30 less-fortunate communities, the prevailing socioeconomic milieu paints a disconcerting picture. The average monthly income per capita languishes at a meager 6000.00 rupees. In the throes of these daunting financial constraints, countless families find themselves grappling with a formidable dilemma: how to afford a standard of education that is both illuminating and transformative.
                        </div>
                    </section>

                    <section className={styles.section}>
                        <h2 className={styles.sectionTitle}>The Reality</h2>
                        <div className={styles.cardContainer}>
                            <div className={styles.card}>
                                <h3 className={styles.cardTitle}>Educational Disparity</h3>
                                <p className={styles.cardText}>
                                    It is a bitter truth that in such dire circumstances, many young girls are compelled to relinquish their cherished educational aspirations, prioritizing the educational pursuits of their male counterparts. The result is a heartbreaking curtailment of opportunities, wherein the promise and potential of these bright, young minds are stifled.
                                </p>
                            </div>
                            <div className={styles.card}>
                                <h3 className={styles.cardTitle}>Untapped Potential</h3>
                                <p className={styles.cardText}>
                                    In essence, the consequences are profound, for within these communities lies a reservoir of untapped brilliance. A multitude of young scholars, replete with the capability to ascend to the pinnacles of academic achievement in esteemed institutions such as the Indian Institutes of Technology (IIT) or the Massachusetts Institute of Technology (MIT), remain imprisoned within the relentless cycles of poverty and obscurity.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className={styles.section}>
                        <h2 className={styles.sectionTitle}>Our Solution</h2>
                        <div className={`${styles.text} ${styles.highlight}`}>
                            However, there exists a beacon of hope in the form of a transformative solution. This dire situation can be rectified through the implementation of an educational paradigm that seamlessly integrates the spiritual and religious dimension with worldly knowledge. Enter ALM, an educational initiative poised to tackle these pressing challenges. ALM can pave the way for a brighter future for India's next generation of bright scholars. Our goal is not the quantity but the quality.
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
}

export default About;