import React from "react";
import { RiAccountPinCircleFill, RiMailFill } from "react-icons/ri";
import { FaMagento, FaPhone } from "react-icons/fa";
import { IoSchool } from "react-icons/io5";
import { FaUniversity } from "react-icons/fa";
import { MdClass } from "react-icons/md";
import styles from '../../Styles/TeacherApplicationForm.module.css';

function TeacherApplicationForm({ formData, handleInputChangedt,handelSave }) {
    // const handleIconClick = (inputId) => {
    //     document.getElementById(inputId).click();
    // };
    return (
        <>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Full Name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <RiAccountPinCircleFill className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Age"
                    name="age"
                    value={formData.age}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <FaMagento className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <RiMailFill className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Phone No."
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <FaPhone className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Name of Your School"
                    name="schoolName"
                    value={formData.schoolName}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <IoSchool className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Name of College/University"
                    name="college"
                    value={formData.college}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <FaUniversity className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Marks Obtained in Class 10"
                    name="classt"
                    value={formData.classt}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <MdClass className={styles.icon} />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    className={styles.regfields}
                    placeholder="Marks Obtained in Class 12"
                    name="classtw"
                    value={formData.classtw}
                    onChange={(e) => handleInputChangedt(e)}
                />
                <MdClass className={styles.icon} />
            </div>
            {/* <div className={styles.input_wrapper_file_upload} onClick={() => handleIconClick('class10Marksheet')} >
                <input
                    type="file"
                    id="class10Marksheet"
                    name="class10Marksheet"
                    style={{ display: "none" }}
                    className={styles.regfields}
                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.png"
                />
                <span>Upload Class 10 Marksheet</span>
                <LiaCertificateSolid className={styles.icon}/>               
            </div>
            <div className={styles.input_wrapper_file_upload} onClick={() => handleIconClick('class12Marksheet')} >
                <input
                    type="file"
                    id="class12Marksheet"
                    name="class12Marksheet"
                    style={{ display: "none" }}
                    className={styles.regfields}
                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.png"
                />
                <span>Upload Class 12 Marksheet</span>
                <LiaCertificateSolid className={styles.icon}/>               
            </div>
            <div className={styles.input_wrapper_file_upload} onClick={() => handleIconClick('graduationMarksheet')} >
                <input
                    type="file"
                    id="graduationMarksheet"
                    name="graduationMarksheet"
                    style={{ display: "none" }}
                    className={styles.regfields}
                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.png"
                />
                <span>Upload Graduation Marksheet</span>
                <LiaCertificateSolid className={styles.icon}/>               
            </div>
            <div className={styles.input_wrapper_file_upload} onClick={() => handleIconClick('adaharCard')} >
                <input
                    type="file"
                    id="adaharCard"
                    name="adaharCard"
                    style={{ display: "none" }}
                    className={styles.regfields}
                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.png"
                />
                <span>Upload your Adahar Card</span>
                <PiIdentificationCardFill className={styles.icon}/>               
            </div>
            <div className={styles.input_wrapper_file_upload} onClick={() => handleIconClick('panCard')} >
                <input
                    type="file"
                    id="panCard"
                    name="panCard"
                    style={{ display: "none" }}
                    className={styles.regfields}
                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.png"
                />
                <span>Upload your Pan Card</span>
                <PiIdentificationCardFill className={styles.icon}/>               
            </div>
            <div className={styles.input_wrapper_file_upload} onClick={() => handleIconClick('photo')} >
                <input
                    type="file"
                    id="photo"
                    name="photo"
                    style={{ display: "none" }}
                    className={styles.regfields}

                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.png"
                />
                <span>Upload your Passport size Photo</span>
                <MdOutlinePermIdentity className={styles.icon}/>               
            </div> */}
            <button className={styles.savebttn} onClick={handelSave} >Apply</button>
        </>
    );
}

export default TeacherApplicationForm;
