import React, { useState, useEffect } from "react";
import styles from '../../Styles/Dropdown.module.css';
import { apiCall } from '../../utils/apiCall';

function FilteredSubjects({ value, onChange, selectedBoard, selectedClassName,selectedAssesmentType}) {
    const [subjectOptions, setSubjectOptions] = useState([]);

    useEffect(() => {
        console.log('Before useEffect:', { selectedBoard, selectedClassName,selectedAssesmentType});

        const fetchSubjectOptions = async () => {
            console.log('Inside fetchChapterOptions:', {selectedBoard, selectedClassName,selectedAssesmentType});

            // Determine endpoint and request body based on selectedAssessmentType
            let endpoint = 'filteredsubjects';
            let requestBody = {
                board: selectedBoard,
                className:selectedClassName,
                assesmentType:selectedAssesmentType
            };
            if (endpoint) {
                console.log('Making API call to:', endpoint);
                try {
                    const response = await apiCall(endpoint, 'POST', requestBody);
                    console.log('API Response:', response);
                    if (response && response.success) {
                        setSubjectOptions(response.subjects || []); // Default to empty array if undefined
                    } else {
                        console.error('Invalid response structure:', response);
                        setSubjectOptions([]); // Ensure chapterOptions is cleared on error
                    }
                } catch (error) {
                    console.error('Error fetching subjects options:', error);
                    setSubjectOptions([]); // Ensure chapterOptions is cleared on error
                }
            } else {
                console.log('No valid endpoint found for fetching subjects.');
                setSubjectOptions([]); // Clear chapterOptions if no valid endpoint
            }
        };

        fetchSubjectOptions();
    }, [selectedBoard, selectedClassName,selectedAssesmentType]);

    return (
        <select className={styles.regfields} value={value} onChange={onChange}>
            <option value="">Select Subject</option>
            {subjectOptions.length > 0 ? (
                subjectOptions.map((subjects, index) => (
                    <option key={index} className={styles.class} value={subjects}>
                        {subjects}
                    </option>
                ))
            ) : (
                <option value="">No Subject Availabel</option>
            )}
        </select>
    );
}

export default FilteredSubjects;
