import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { apiCallAdmin } from '../../utils/apiCallAdmin';
import AdminLayout from '../../Layouts/AdminLayout';
import { FaBook } from "react-icons/fa";
import { AssementTable } from '../../components/Admin/index';
import styles from '../../Styles/StudentDetails.module.css';

export default function StudentDetailPage() {
    let { student_id } = useParams();
    const location = useLocation();
    const [studentDetails, setStudentDetails] = useState(null);


    useEffect(() => {
        fetchStudentDetails();
    }, [student_id]);

    const fetchStudentDetails = async () => {
        try {
            const email = location.state?.email;
            console.log("Email>>>>>>>>>", email);
            if (!email) {
                console.error('Email is missing');
                return;
            }
            const response = await apiCallAdmin(`stdrecord/${email}`, 'GET');
            console.log("RESPONSE >>>>>>>>>>>>>>>>>>>>>>>>", response);
            if (!response.registration) {
                console.error('Student registration data not found');
                return;
            }
            const { progress, registration } = response;

            const studentData = {
                fullName: registration.fullName,
                age: registration.age,
                phoneNo: registration.phoneNo,
                schoolName: registration.schoolName,
                studentClass: registration.studentClass,
                board: registration.board,
                subjects: JSON.parse(registration.subjects), // Parse subjects from JSON string to object
                assessments: progress.map(item => ({
                    subject: item.subject,
                    assessmentType: item.assessmentType,
                    marksObtained: item.marksObtained,
                    assessmentDate: item.assessmentDate
                }))
            };

            setStudentDetails(studentData);
        } catch (error) {
            console.error('Error fetching student details:', error);
        }
    };

    const assessmentColumns = [
        {
            header: 'Subject',
            accessorKey: 'subject',
        },
        {
            header: 'Assessment Type',
            accessorKey: 'assessmentType',
        },
        {
            header: 'Marks Obtained',
            accessorKey: 'marksObtained',
        },
        {
            header: 'Assessment Date',
            accessorKey: 'assessmentDate',
        },
    ];

    return (
        <AdminLayout>
            {studentDetails && (
                <div className={styles.main}>
                    <div className={styles.card1}>
                        <div className={styles.heading}>Personal Details</div>
                        <div className={styles.container1}>
                            <p>Name: {studentDetails.fullName}</p>
                            <p>Age: {studentDetails.age}</p>
                            <p>Phone No.: {studentDetails.phoneNo}</p>
                            <p>School Name: {studentDetails.schoolName}</p>
                            <p>Class: {studentDetails.studentClass}</p>
                            <p>Board: {studentDetails.board}</p>
                        </div>
                    </div>

                    <div className={styles.heading}>Subjects:</div>
                    {studentDetails.subjects && studentDetails.subjects.length > 0 ? (
                        <div className={styles.container1}>
                            {studentDetails.subjects.map((subject, index) => (
                                <div key={index} className={styles.containerCard}>
                                <FaBook className={styles.bookIcon} />
                                    <div>{subject.subjectName}</div>
                                     <div> {subject.bookName} </div>
                                     <div> {subject.author} </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No subjects found.</p>
                    )}



                    {studentDetails.assessments && studentDetails.assessments.length > 0 ? (
                        <AssementTable data={studentDetails.assessments} columns={assessmentColumns} />
                    ) : (
                        <p>No assessments found.</p>
                    )}
                </div>
            )}
        </AdminLayout>
    );
}
