import TaecherTopPannel from "../components/Teacher/TaecherTopPannel";
import TextHolder from "../components/Teacher/TextHolder";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../utils/apiCall";

export default function TeacherLayout({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    // Define the logout function
    const handleAutoLogout = () => {
      console.log("User has been inactive for 2 hours, logging out...");
      alert("You have been inactive for 2 hours. You will be logged out.");
      localStorage.removeItem("teachertoken");
      sessionStorage.removeItem("refreshTokenTeacher");
      navigate("/TeacherLogin");
    };

    // Define the timeout duration (2 hours = 7200000 ms)
    const timeoutDuration = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    let timeoutId;

    // Reset the timeout whenever there is user activity
    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleAutoLogout, timeoutDuration);
    };

    // Listen for user activity (mouse movement, key press, etc.)
    const activityEvents = ["mousemove", "keydown", "click"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimeout)
    );

    // Start the initial timeout
    timeoutId = setTimeout(handleAutoLogout, timeoutDuration);

    // Cleanup event listeners and timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
    };
  }, [navigate]);


  useEffect(() => {
    const refreshUserToken = async () => {
      try {
        const expiredUserToken = localStorage.getItem("teachertoken");
        const refreshToken = sessionStorage.getItem("refreshTokenTeacher");

        console.log("Attempting to refresh token...");
        console.log("Expired User Token:", expiredUserToken);
        console.log("Refresh Token:", refreshToken);

        if (expiredUserToken && refreshToken) {
          const response = await apiCall(
            "refreshtoken",
            "POST",
            { refreshToken: refreshToken },
            { authorization: expiredUserToken } // Pass the expired token in headers
          );

          console.log("API Response:", response);

          if (response.token) {
            localStorage.setItem("teachertoken", response.token);
            console.log("User token updated.");
          } else if (response.message === "Please login again") {
            alert(response.message || "Please login again");
            navigate('/TeacherLogin');
          } else {
            console.log("Token is still valid or an error occurred:", response);
          }
        } else {
          console.log("Missing tokens; API call skipped.");
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    };

    const intervalId = setInterval(refreshUserToken, 30000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <div>
      <TaecherTopPannel />
      {children}
    </div>
  );
}
