import React, { useState, useEffect } from "react";
import styles from '../../Styles/Dropdown.module.css';
import { apiCall } from '../../utils/apiCall';

function Chapter({ value, onChange }) {
  const [chapterOptions, setChapterOptions] = useState([]);

  useEffect(() => {
    const fetchChapterOptions = async () => {
      try {
        const response = await apiCall('chapters','GET');
        setChapterOptions(response.chapters);
      } catch (error) {
        console.error('Error fetching chapter options:', error);
      }
    };
  
    fetchChapterOptions();
  }, []);

  return (
    <select className={styles.regfields} value={value} onChange={onChange}>
      <option value="">Select Chapter</option>
      {chapterOptions.map((option, index) => (
        <option key={index} className={styles.class} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Chapter;