import React, { useState, useEffect } from "react";
import styles from '../../Styles/Navbar.module.css';
import imageSrc from "../../Pages/images/18.png";
import { useNavigate } from "react-router-dom";

function Navbar() {
    const [menuActive, setMenuActive] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    // Check if the user is logged in by checking the presence of a token
    useEffect(() => {
        const userToken = localStorage.getItem('usertoken');
        setIsLoggedIn(!!userToken); // Set to true if the token exists, otherwise false
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
        setMenuActive(false); // Close menu after navigation
    };

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    const handleLogout = () => {
        // Clear tokens from sessionStorage
        localStorage.removeItem('usertoken');
        sessionStorage.removeItem('refreshToken');
        
        // Set the user as logged out
        setIsLoggedIn(false);

        // Redirect to the home page after logout
        navigate("/login");
    };

    return (
        <div className={styles.main}>
            <img src={imageSrc} alt="Website Logo" className={styles.logo} />
            
            {/* Hamburger Menu */}
            <div className={styles.hamburger} onClick={toggleMenu}>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
            </div>

            {/* Navigation Menu */}
            <div className={`${styles.navmenu} ${menuActive ? styles.active : ""}`}>
                <p onClick={() => handleNavigation("/")} className={styles.link}>Home</p>
                <p onClick={() => handleNavigation("/about")} className={styles.link}>About Us</p>
                <p onClick={() => handleNavigation("/login")} className={styles.link}>Log In</p>
                <p onClick={() => handleNavigation("/registration")} className={styles.link}>Registration</p>
                <p onClick={() => handleNavigation("/learn")} className={styles.link}>Learn Here</p>

                {/* Conditionally render Logout if logged in */}
                {isLoggedIn && (
                    <p onClick={handleLogout} className={styles.link}>Logout</p>
                )}
            </div>
        </div>
    );
}

export default Navbar;
