import React, { useState } from "react";
import styles from '../../Styles/UploaderPage.module.css';
import { apiCall } from '../../utils/apiCall';
import { FileUploaderForm } from "../../components/Admin/index";
import AdminLayout from "../../Layouts/AdminLayout";

function UploderPage() {
    const [show_student_details, setStudentDetails] = useState(false);
    const [show_data_basedetails, setDataBasedetails] = useState(false);
    const [is_lecture_checked, setLectureChecked] = useState(false);
    const [is_test_checked, setTestChecked] = useState(false);
    const [selected_board, setSelectedBoard] = useState("");
    const [selected_class_name, setSelectedClassName] = useState("");
    const [selected_subject, setSelectedSubject] = useState("");
    const [file_type, setFileType] = useState("");
    const [file, setFile] = useState(null);
    const [selected_test_type, setSelectedTestType] = useState("");


    // const handle_database = () => {
    //     setDataBasedetails(!show_data_basedetails);
    // }
    const handle_lecture_checkbox_change = () => {
        setLectureChecked(true);
        setTestChecked(false);
        setFileType("Lecture");
    };

    const handle_test_checkbox_change = () => {
        setTestChecked(true);
        setLectureChecked(false);
        setFileType("Test");
        setSelectedTestType("");
    };

    const handle_browse_files = () => {
        document.getElementById("fileInput").click();
    };

    const handle_file_select = (e) => {
        const selected_files = Array.from(e.target.files); // Handle multiple files
        setFile(selected_files); // Store selected files in state
    };
    
    //console.log(selected_test_type);
    const convert_to_base64 = () => {
        let set_base64_img;
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = function (e) {
            const file_content = e.target.result;
         //   console.log("File content:", file_content);
            set_base64_img = file_content;

            // You can use the file content as needed, for example, send it to a server.
        };
        return set_base64_img;

    }
    // const handle_upload = async () => {
    //     // console.log('Selected Board:', selected_board);
    //     // console.log('Selected Class:', selected_class_name);
    //     // console.log('Selected Subject:', selected_subject);
    //     // console.log('File Type:', file_type);
    //     // console.log('Selected File:', file);
    //     // console.log('Selected Test Type:', selected_test_type);

    //     if (!selected_board || !selected_class_name || !selected_subject || !file_type || !file) {
    //         console.error('Missing required information');
    //         return;
    //     }

    //     const form_data = new FormData();
    //     form_data.append('file', file);
    //     form_data.append('selected_board', selected_board);
    //     form_data.append('selected_class_name', selected_class_name);
    //     form_data.append('selected_subject', selected_subject);
    //     form_data.append('file_type', file_type);
    //     //let base64file = convert_to_base64();
    //     //let payload = {binfile:formData.base64file}


    //     // console.log("igdiasbdjasdajsdbjahsdbjahsdja",base64file); 
    //     try {
    //         const file_reader = new FileReader();
    //         file_reader.onloadend = async () => {
    //             const base64_file = file_reader.result.split(',')[1];
    //             const request_data = {
    //                 selected_board,
    //                 selected_class_name,
    //                 selected_subject,
    //                 file_type,
    //                 base64_file,
    //             };
    //             console.log("REQUREST DATA",request_data)
    //             if (is_test_checked) {
    //                 request_data.selected_test_type = selected_test_type;
    //             }
    //           //  console.log("DATA", request_data);
    //             form_data.append('base64_file', base64_file);
    //             let endpoint = '';
    //             if (file_type === 'Lecture') {
    //                 endpoint = 'lecture/create';
    //             } else if (file_type === 'Test') {
    //                 endpoint = 'test/create';
    //             }
    //             const response = await apiCall(endpoint, 'POST',request_data)
    //             if (response.success) {
    //                 //console.log('File uploaded successfully!');
    //                 alert('File uploaded successfully!');
    //             } else {
    //                 console.error('File upload failed:', response.statusText);
    //             }
    //         };

    //         file_reader.readAsDataURL(file);
    //     } catch (error) {
    //         console.error('Error uploading file:', error.message);
    //     }
    // };
    const handle_upload = async () => {
        if (!selected_board || !selected_class_name || !selected_subject || !file_type || !file || file.length === 0) {
            console.error('Missing required information');
            return;
        }
    
        const form_data = new FormData();
        form_data.append('selected_board', selected_board);
        form_data.append('selected_class_name', selected_class_name);
        form_data.append('selected_subject', selected_subject);
        form_data.append('file_type', file_type);
    
        if (is_test_checked) {
            form_data.append('selected_test_type', selected_test_type);
        }
    
        try {
            const filePromises = file.map((singleFile) => {
                return new Promise((resolve, reject) => {
                    const file_reader = new FileReader();
                    file_reader.onloadend = () => {
                        const base64_file = file_reader.result.split(',')[1];
                        resolve({
                            fileName: singleFile.name,
                            base64File: base64_file,
                        });
                    };
                    file_reader.onerror = reject;
                    file_reader.readAsDataURL(singleFile);
                });
            });
    
            const base64Files = await Promise.all(filePromises); // Wait for all file conversions
    
            const base64FileArray = base64Files.map((fileObj) => fileObj.base64File); // Collect all base64 files in an array
    
            // Prepare the request body
            const request_data = {
                selected_board,
                selected_class_name,
                selected_subject,
                file_type,
                base64_files: base64FileArray, // Send all base64 files as an array
            };
    
            if (is_test_checked) {
                request_data.selected_test_type = selected_test_type;
            }
    
            let endpoint = file_type === 'Lecture' ? 'lecture/create' : 'test/create';
    
            // Send the request
            const response = await apiCall(endpoint, 'POST', request_data); // Using request_data instead of form_data
    
            if (response.success) {
                alert('Files uploaded successfully!');
            } else {
                console.error('File upload failed:', response.message);
            }
        } catch (error) {
            console.error('Error uploading files:', error.message);
        }
    };
    

    return (
       <AdminLayout>
                <FileUploaderForm
                    isLectureChecked={is_lecture_checked}
                    isTestChecked={is_test_checked}
                    handleLectureCheckboxChange={handle_lecture_checkbox_change}
                    handleTestCheckboxChange={handle_test_checkbox_change}
                    selectedboard={selected_board}
                    setSelectedboard={setSelectedBoard}
                    selectedclassName={selected_class_name}
                    setSelectedclassName={setSelectedClassName}
                    selectedsubject={selected_subject}
                    setSelectedsubject={setSelectedSubject}
                    selectedtestType={selected_test_type}
                    setSelectedTestType={setSelectedTestType}
                    handleBrowseFiles={handle_browse_files}
                    handleUpload={handle_upload}
                    handleFileSelect={handle_file_select}
                />
     </AdminLayout>
    );
}

export default UploderPage;
