import React, { useState } from "react";
import styles from '../../Styles/FileUploaderForm.module.css';
import { ClassDropdown, Board, Subject, Test } from "../../components/Dropdowns/index";
import { VscBrowser } from "react-icons/vsc";
import { MdOutlineFileUpload } from "react-icons/md";

function FileUploaderForm({
    isLectureChecked,
    isTestChecked,
    handleLectureCheckboxChange,
    handleTestCheckboxChange,
    selectedboard,
    setSelectedboard,
    selectedclassName,
    setSelectedclassName,
    selectedsubject,
    setSelectedsubject,
    selectedtestType,
    setSelectedTestType,
    handleBrowseFiles,
    handleUpload,
    handleFileSelect
}) {
    const [selectedFileName, setSelectedFileName] = useState(''); // State to store the selected file name
    const [isUploaded, setIsUploaded] = useState(false); // State to track if the file is uploaded
    const [uploadProgress, setUploadProgress] = useState(0);
    // const onFileSelect = (e) => {
    //     handleFileSelect(e); // Call the parent handler
    //     if (e.target.files.length > 0) {
    //         setSelectedFileName(e.target.files[0].name); // Set the selected file name
    //         setIsUploaded(false); // Reset the upload status when a new file is selected
    //     } else {
    //         setSelectedFileName(''); // Clear the file name if no file is selected
    //         setIsUploaded(false); // Reset the upload status
    //     }
    // };
    
    const onFileSelect = (e) => {
        handleFileSelect(e); // Call the parent handler
        if (e.target.files.length > 0) {
            const fileNames = Array.from(e.target.files).map(file => file.name).join(', '); // Handle multiple file names
            setSelectedFileName(fileNames); // Set the selected file names
            setIsUploaded(false); // Reset the upload status when new files are selected
        } else {
            setSelectedFileName(''); // Clear the file name if no files are selected
            setIsUploaded(false); // Reset the upload status
        }
    };

    const onUpload = () => {
        setIsUploaded(false); // Reset the upload status when starting upload
        setUploadProgress(0); // Reset progress

        const simulateUpload = () => {
            let progress = 0;
            const interval = setInterval(() => {
                progress += 10;
                setUploadProgress(progress);
                if (progress >= 100) {
                    clearInterval(interval);
                    setIsUploaded(true); // Mark as uploaded when done
                }
            }, 300); // Simulate progress in increments
        };

        simulateUpload(); // Call the simulation function
        handleUpload(); // Call the parent upload handler
    };
    return (
        <div className={styles.database_modal}>
            <div className={styles.checkboxContainer}>
                <div className={styles.checkboxWrapper}>
                    <div className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={isLectureChecked}
                            onChange={handleLectureCheckboxChange}
                        />
                        Lecture
                    </div>
                    <div className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={isTestChecked}
                            onChange={handleTestCheckboxChange}
                        />
                        Test
                    </div>
                </div>
            </div>
            <div className={styles.drop_container}>
                <Board value={selectedboard} onChange={(e) => setSelectedboard(e.target.value)} />
            </div>
            <div className={styles.drop_container}>
                <ClassDropdown value={selectedclassName} onChange={(e) => setSelectedclassName(e.target.value)} />
            </div>
            <div className={styles.drop_container}>
                <Subject value={selectedsubject} onChange={(e) => setSelectedsubject(e.target.value)} />
            </div>
            {isTestChecked && (
                <div className={styles.drop_container}>
                    <Test value={selectedtestType} onChange={(e) => setSelectedTestType(e.target.value)} />
                </div>
            )}
            <div className={styles.file_button}>
                <button className={styles.dashboard_button_files} onClick={handleBrowseFiles}>
                    Browse <VscBrowser className={styles.icon} />
                </button>
                <button className={styles.dashboard_button_files} onClick={onUpload} >
                    Upload <MdOutlineFileUpload className={styles.icon} />
                </button>
            </div>
            {selectedFileName && (
                <div className={styles.file_name_display}>
                    {isUploaded ? (
                        <span className={`${styles.file_name} ${styles.uploaded}`}>
                            Uploaded: {selectedFileName}
                        </span>
                    ) : (
                        <>
                            <span className={styles.file_name}>
                                Uploading: {selectedFileName}
                            </span>
                            <div className={styles.progressBarContainer}>
                                <div
                                    className={styles.progressBar}
                                    style={{ width: `${uploadProgress}%` }}
                                ></div>
                            </div>
                            <span className={styles.progressText}>{uploadProgress}%</span>
                        </>
                    )}
                </div>
            )}
            {/* <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={onFileSelect}
            /> */}
            <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={onFileSelect}
                multiple // Allow multiple file selection
            />
        </div>
    );
}

export default FileUploaderForm;
