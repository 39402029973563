import React, { useState, useEffect } from "react";
import styles from '../../Styles/Dropdown.module.css';
import { apiCall } from '../../utils/apiCall';

function Chapter({ value, onChange, selectedBoard, selectedClassName, selectedSubject, selectedTestType, selectedAssesmentType }) {
    console.log("Selected Class in ChapterDemo:", selectedClassName);
    const [chapterOptions, setChapterOptions] = useState([]);

    useEffect(() => {
        console.log('Before useEffect:', { selectedBoard, selectedClassName, selectedSubject, selectedTestType, selectedAssesmentType });

        const fetchChapterOptions = async () => {
            console.log('Inside fetchChapterOptions:', { selectedBoard, selectedClassName, selectedSubject, selectedTestType, selectedAssesmentType });

            // Determine endpoint and request body based on selectedAssessmentType
            let endpoint = '';
            let requestBody = {};

            if (selectedAssesmentType === 'Test') {
                endpoint = 'chapterTest';
                requestBody = {
                    board: selectedBoard,
                    className: selectedClassName,
                    subject: selectedSubject,
                    testType: selectedTestType,
                };
            } else if (selectedAssesmentType === 'Lecture') {
                endpoint = 'chapterLecture';
                requestBody = {
                    board: selectedBoard,  // Assuming you want empty strings for Lecture
                    className: selectedClassName,
                    subject: selectedSubject
                };
            }

            if (endpoint) {
                console.log('Making API call to:', endpoint);
                try {
                    const response = await apiCall(endpoint, 'POST', requestBody);
                    console.log('API Response:', response);
                    if (response && response.success) {
                        setChapterOptions(response.chapters || []); // Default to empty array if undefined
                    } else {
                        console.error('Invalid response structure:', response);
                        setChapterOptions([]); // Ensure chapterOptions is cleared on error
                    }
                } catch (error) {
                    console.error('Error fetching chapter options:', error);
                    setChapterOptions([]); // Ensure chapterOptions is cleared on error
                }
            } else {
                console.log('No valid endpoint found for fetching chapters.');
                setChapterOptions([]); // Clear chapterOptions if no valid endpoint
            }
        };

        fetchChapterOptions();
    }, [selectedBoard, selectedClassName, selectedSubject, selectedTestType, selectedAssesmentType]);

    return (
        <select className={styles.regfields} value={value} onChange={onChange}>
            <option value="">Select Chapter</option>
            {chapterOptions.length > 0 ? (
                chapterOptions.map((chapter, index) => (
                    <option key={index} className={styles.class} value={chapter}>
                        {chapter}
                    </option>
                ))
            ) : (
                <option value="">No chapters available</option>
            )}
        </select>
    );
}

export default Chapter;
