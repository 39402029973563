import TopPannel from "../components/Admin/TopPannel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminLayout({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const timeoutDuration = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    let timeoutId;

    const handleAutoLogout = () => {
      console.log("User has been inactive for 2 hours, logging out...");
      alert("You have been inactive for 2 hours. You will be logged out.");
      localStorage.removeItem("usertoken");
      sessionStorage.removeItem("refreshToken");
      navigate("/Adminlogin");
    };

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleAutoLogout, timeoutDuration);
    };

    // Listen for user activity
    const activityEvents = ["mousemove", "keydown", "click"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimeout)
    );

    // Start the initial timeout
    timeoutId = setTimeout(handleAutoLogout, timeoutDuration);

    return () => {
      clearTimeout(timeoutId);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
    };
  }, [navigate]); 
  // Add navigate to dependencies
  return (
    <div>
      <TopPannel />
      {children}
    </div>
  );
}