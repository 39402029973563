import React from 'react'
import styles from '../../Styles/Banner.module.css';

export default function Banner() {
    return (
        <div className={styles.bannerContainer}>
            <div className={styles.bannerContent}>
                <h1 className={styles.bannerTitle}>Start Your Learning Journey Today!</h1>
                <p className={styles.bannerText}>Explore a wide range of courses and assessments</p>
                <button className={styles.bannerButton}>Get Started</button>
            </div>
        </div>
    )
}
