import React, { useState, useEffect } from 'react';
import { MdGridView } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { apiCallAdmin } from '../../utils/apiCallAdmin';
import styles from '../../Styles/LandingPage.module.css';
import AdminLayout from '../../Layouts/AdminLayout';
import { UploaderTable } from '../../components/Admin';

function LandingPage() {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllData();
    }, []); 

    const fetchAllData = async () => {
        try {
            const response = await apiCallAdmin('studentDetails', 'GET');
            console.log("RESPONSE", response);
            if (response.success && response.students) {
                setTableData(response.students);
            } else {
                console.error('Error fetching data:', response.error || 'Unexpected response format');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const openStudentDetailsPage = (cell) => {
        const { student_id, email } = cell.row.original;
        console.log("Opening details for student_id:", student_id);
        navigate(`/StudentDetailPage/${student_id}`, { state: { email } });
    };
    
    const columns = [
        {
            header: "SL NO.",
            accessorKey: "student_id",
            cell: (info) => info.row.index + 1
        },
        {
            header: "Student Name",
            accessorKey: "fullName",
        },
        {
            header: "Class",
            accessorKey: "studentClass",
        },
        {
            header: "Board",
            accessorKey: "board",
        },
        {
            header: "Phone No.",
            accessorKey: "phoneNo",
        },
        {
            header: "Student Details",
            accessorKey: "student_id",
            cell: (cell) => (
                <MdGridView
                    onClick={() => openStudentDetailsPage(cell)} 
                    style={{ marginLeft: "10px", cursor: "pointer", color: "aqua" }}
                />
            ),
        }
    ];

    return (
        <AdminLayout>
            <div className={styles.tableWrapper}>
                {tableData.length > 0 ? (
                    <UploaderTable
                        data={tableData}
                        columns={columns}
                    />
                ) : (
                    <p>No data available</p>
                )}
            </div>
        </AdminLayout>
    );
}

export default LandingPage;
