import React, { useState, useRef, useEffect } from 'react';
import { IoIosSave } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { GoPaperAirplane } from "react-icons/go";
import { apiCall } from '../../utils/apiCall';
import { RxCross2 } from "react-icons/rx";
import styles from '../../Styles/CreateTest.module.css';
import { Board, Subject, ClassDropdown, Chapter, Test } from "../Dropdowns/index";

export default function CreateLecture() {
    const [selected_board, setSelectedboard] = useState('');
    const [selected_className, setSelectedclassName] = useState('');
    const [selected_subject, setSelectedsubject] = useState('');
    const [selected_chapter, setSelectedchapter] = useState('');
    const [selectedtestType, setSelectedtestType] = useState(''); // For exam_type
    const [chapterName, setChapterName] = useState('');
    const [questions, setQuestions] = useState([{
        question_number: '',
        question: '',
        options: ['', '', '', ''], // For MCQ
        answer: '',
        score: ''
    }]);

    const [errors, setErrors] = useState({
        board: '',
        className: '',
        subject: '',
        chapter: '',
        chapterName: '',
        questionFields: []
    });

    const questionContainerRef = useRef(null);

    const addQuestion = () => {
        setQuestions([...questions, {
            question_number: '',
            question: '',
            options: ['', '', '', ''], // Keep options for MCQ
            answer: '',
            score: ''
        }]);
    };

    useEffect(() => {
        if (questionContainerRef.current) {
            questionContainerRef.current.scrollTop = questionContainerRef.current.scrollHeight;
        }
    }, [questions]);

    const removeQuestion = (index) => {
        const updatedQuestions = questions.filter((_, questionIndex) => questionIndex !== index);
        setQuestions(updatedQuestions);
    };

    const handleQuestionInputChange = (index, e) => {
        const updatedQuestions = questions.map((question, questionIndex) =>
            questionIndex === index ? { ...question, [e.target.name]: e.target.value } : question
        );
        setQuestions(updatedQuestions);
    };

    const handleOptionChange = (questionIndex, optionIndex, e) => {
        const updatedQuestions = questions.map((question, qIndex) =>
            qIndex === questionIndex ? {
                ...question,
                options: question.options.map((opt, oIndex) => oIndex === optionIndex ? e.target.value : opt)
            } : question
        );
        setQuestions(updatedQuestions);
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            board: '',
            className: '',
            subject: '',
            chapter: '',
            chapterName: '',
            questionFields: []
        };

        if (!selected_board) {
            newErrors.board = 'Board is required';
            valid = false;
        }

        if (!selected_className) {
            newErrors.className = 'Class is required';
            valid = false;
        }

        if (!selected_subject) {
            newErrors.subject = 'Subject is required';
            valid = false;
        }

        if (!selected_chapter) {
            newErrors.chapter = 'Chapter is required';
            valid = false;
        }

        if (!chapterName) {
            newErrors.chapterName = 'Chapter Name is required';
            valid = false;
        }

        const updatedQuestionErrors = questions.map((question, index) => {
            const questionErrors = [];
            if (!question.question_number) questionErrors.push('Question number is required');
            if (!question.question) questionErrors.push('Question is required');
            if (!question.answer) questionErrors.push('Answer is required');
            if (!question.score) questionErrors.push('Score is required');
            return questionErrors;
        });

        updatedQuestionErrors.forEach(errors => {
            if (errors.length > 0) valid = false;
        });

        newErrors.questionFields = updatedQuestionErrors;

        setErrors(newErrors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        const testData = {
            board: selected_board,
            grade: selected_className,
            subject_name: selected_subject,
            alt_subject_name: chapterName,
            chapter_number: selected_chapter,
            chapter_name: chapterName,
            question: questions.map((q) => ({
                exam_type: selectedtestType,
                question_number: q.question_number,
                question: q.question,
                answer: q.answer,
                score: q.score,
                ...(selectedtestType === 'mcq' ? { options: q.options } : {}) // Include options for MCQ
            }))
        };

        const binfile = btoa(JSON.stringify(testData));
        const request_data = {
            selected_board,
            selected_class_name: selected_className,
            selected_subject: selected_subject,
            file_type: "Test",
            selected_test_type: selectedtestType,
            base64_file: binfile,
        };

        try {
            const response = await apiCall('test/create', 'POST', request_data);
            if (response.success) {
                alert("Test created successfully");
            } else {
                alert(`Error: ${response.message}`);
            }
        } catch (error) {
            console.error("Error while saving test:", error);
            alert("An error occurred while creating the test.");
        }
    };

    return (
        <div className={styles.formContainer}>
            <div className={styles.dropdownContainer}>
                <div className={styles.drop_container}>
                    <Board value={selected_board} onChange={(e) => setSelectedboard(e.target.value)} />
                    {errors.board && <p className={styles.errorText}>{errors.board}</p>}
                </div>
                <div className={styles.drop_container}>
                    <ClassDropdown value={selected_className} onChange={(e) => setSelectedclassName(e.target.value)} />
                    {errors.className && <p className={styles.errorText}>{errors.className}</p>}
                </div>
                <div className={styles.drop_container}>
                    <Subject value={selected_subject} onChange={(e) => setSelectedsubject(e.target.value)} />
                    {errors.subject && <p className={styles.errorText}>{errors.subject}</p>}
                </div>
                <div className={styles.drop_container}>
                    <Chapter value={selected_chapter} onChange={(e) => setSelectedchapter(e.target.value)} />
                    {errors.chapter && <p className={styles.errorText}>{errors.chapter}</p>}
                </div>
                <div className={styles.drop_container}>
                    <Test value={selectedtestType} onChange={(e) => setSelectedtestType(e.target.value)} />
                    {errors.testType && <p className={styles.errorText}>Test type is required</p>}
                </div>
            </div>

            <input
                type="text"
                name="chapterName"
                value={chapterName}
                placeholder="Chapter Name"
                className={styles.inputField}
                onChange={(e) => setChapterName(e.target.value)}
            />
            {errors.chapterName && <p className={styles.errorText}>{errors.chapterName}</p>}

            <div className={styles.cardContainer}>
                <div className={styles.questionContainer} ref={questionContainerRef}>
                    {questions.map((question, index) => (
                        <div key={index} className={styles.questionCard}>
                            <div className={styles.headerContainer}>
                                <p className={styles.head_text}>
                                    <GoPaperAirplane className={styles.logo} /> Question {index + 1}
                                </p>
                                <button className={styles.removeButton} onClick={() => removeQuestion(index)}>
                                    <RxCross2 />
                                </button>
                            </div>

                            <input
                                type="text"
                                name="question_number"
                                value={question.question_number}
                                placeholder="Question Number"
                                className={styles.inputField}
                                onChange={(e) => handleQuestionInputChange(index, e)}
                            />
                            {errors.questionFields[index] && errors.questionFields[index].includes('Question number is required') && (
                                <p className={styles.errorText}>Question number is required</p>
                            )}

                            <textarea
                                name="question"
                                value={question.question}
                                placeholder="Enter Question"
                                className={styles.inputField}
                                onChange={(e) => handleQuestionInputChange(index, e)}
                            />
                            {errors.questionFields[index] && errors.questionFields[index].includes('Question is required') && (
                                <p className={styles.errorText}>Question is required</p>
                            )}

                            {selectedtestType === 'mcq' && (
                                <>
                                    <p className={styles.optionTitle}>Options:</p>
                                    {question.options.map((option, optionIndex) => (
                                        <input
                                            key={optionIndex}
                                            type="text"
                                            value={option}
                                            placeholder={`Option ${optionIndex + 1}`}
                                            className={styles.inputField}
                                            onChange={(e) => handleOptionChange(index, optionIndex, e)}
                                        />
                                    ))}
                                </>
                            )}

                            <input
                                type="text"
                                name="answer"
                                value={question.answer}
                                placeholder="Enter Answer"
                                className={styles.inputField}
                                onChange={(e) => handleQuestionInputChange(index, e)}
                            />
                            {errors.questionFields[index] && errors.questionFields[index].includes('Answer is required') && (
                                <p className={styles.errorText}>Answer is required</p>
                            )}

                            <input
                                type="text"
                                name="score"
                                value={question.score}
                                placeholder="Enter Score"
                                className={styles.inputField}
                                onChange={(e) => handleQuestionInputChange(index, e)}
                            />
                            {errors.questionFields[index] && errors.questionFields[index].includes('Score is required') && (
                                <p className={styles.errorText}>Score is required</p>
                            )}
                        </div>
                    ))}
                </div>

               
            </div>

            <div className={styles.buttonContainer}>
            <button onClick={addQuestion} className={styles.addButton}>
                    <MdAdd className={styles.logo} /> Add Question
                </button>
                <button className={styles.saveButton} onClick={handleSave}>
                    <IoIosSave className={styles.logo} /> Save
                </button>
            </div>
        </div>
    );
}
