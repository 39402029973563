import React, { useEffect, useState } from 'react';
import styles from '../../Styles/TaecherTopPannel.module.css';
import imageSrc from "../../Pages/images/18.png";
import { useNavigate } from 'react-router-dom';

function TaecherTopPannel() {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // Check if the teacher is logged in by checking the presence of a token
    useEffect(() => {
        const teachertoken = localStorage.getItem('teachertoken');
        setIsLoggedIn(!!teachertoken); // Set to true if the token exists, otherwise false
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleLogout = () => {
        // Clear tokens from sessionStorage
        localStorage.removeItem('teachertoken');
        sessionStorage.removeItem('refreshTokenTeacher');

        // Set the user as logged out
        setIsLoggedIn(false);

        // Redirect to home or login page after logout
        navigate("/TeacherLogin");
    };

    return (
        <div className={styles.main}>
            <img src={imageSrc} alt="Website Logo" className={styles.logo} />
            <p className={styles.tag} onClick={() => handleNavigation("/TeacherReg")}>Apply Here!</p>
            
            {/* Login is always visible */}
            <p className={styles.tag} onClick={() => handleNavigation("/TeacherLogin")}>Login</p>

            {/* Logout is only visible when the user is logged in */}
            {isLoggedIn && (
                <p className={styles.tag} onClick={handleLogout}>Logout</p>
            )}
        </div>
    );
}

export default TaecherTopPannel;
