import React, { useState } from 'react'
import styles from '../../Styles/TeacherReg.module.css';
import TeacherApplicationForm from '../../components/Teacher/TeacherApplicationForm';
import TeacherLayout from '../../Layouts/TeacherLayout';
import { useNavigate } from "react-router-dom";
import { apiCall } from '../../utils/apiCallTeacher';
import TaecherTopPannel from '../../components/Teacher/TaecherTopPannel';

export default function TeacherReg() {
    const navigate = useNavigate();
    const [form_Data, setFormData] = useState({
        fullName: "",
        age: "",
        phoneNo: "",
        email: "",
        schoolName: "",
        college: "",
        classt: "",
        classtw: "",
        // class10Marksheet: null,
        // class12Marksheet: null,
        // graduationMarksheet: null,
        // adaharCard: null,
        // panCard: null,
        // photo: null
    });

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData((prevData) => ({
                ...prevData,
                [name]: reader.result,
            }));
        };
        reader.readAsDataURL(file);
    };

    const handelSave = async () => {
        console.log("FORM_DATA------>>>>>>", form_Data);
        const endpoint = 'teacherReg';
        try {
            const response = await apiCall(endpoint, 'POST', form_Data);
            console.log("API RESPONSE>>>", response);
    
            // Check if the response indicates success
            if (response.success) {
                alert('Registration Successful');
                // Optionally, navigate to another page
                 //navigate('/TeacherLogin');
            } else {
                alert('Registration failed: ' + response.message);
            }
        } catch (error) {
            console.error('API call error:', error);
            alert('An error occurred during registration. Please try again.');
        }
    };

    const handleInputChangedt = (e) => {
        const { name, value } = e.target;
        console.log("VALUE>>>>>>>>>",e.target.value)
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
        <TaecherTopPannel /> 
            <div className={styles.main}>
                <div className={styles.cardContainer}>
                    <TeacherApplicationForm formData={form_Data} handleInputChangedt={handleInputChangedt} handelSave={handelSave} handleFileChange={handleFileChange}/>
                </div>
            </div>
            </div>
    )
}


