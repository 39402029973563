import React, { useState } from "react";
import styles from '../../Styles/Learn.module.css';
import { apiCall } from '../../utils/apiCall';
import { useNavigate } from 'react-router-dom';
import { LectureData, TestDetails } from "../../components/Learn/index";
import Banner from "../../components/common/Banner";
import { Board, Subject, Assesment, Test, ChapterDemo, DynamicClassName, FilteredSubjects, ClassDropdown } from "../../components/Dropdowns/index";
import GeneralLayout from "../../Layouts/GeneralLayout";
import { IoBookSharp } from "react-icons/io5";


function Learnhere() {
    const [show_course_details, setShowCourseDetails] = useState(false);
    const [show_test_details, setShowTestDetails] = useState(false);
    const [selected_board, setSelectedboard] = useState("");
    const [selected_className, setSelectedclassName] = useState("");
    const [selected_fileType, setSelectedfiletype] = useState("");
    const [selected_subject, setSelectedsubject] = useState("");
    const [selected_chapter, setSelectedchapter] = useState("");
    const [course_Data, setCourseData] = useState(null);
    const [test_Data, setTestData] = useState(null);
    const [selected_Options, setSelectedOptions] = useState([]);
    const [filled_Blanks, setFilledBlanks] = useState([]);
    const [show_answer, setShowanswer] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedtestType, setSelectedtestType] = useState("");
    const navigate = useNavigate();
    const handleSubmit = async () => {
        const assessmentDate = new Date().toISOString().split('T')[0];

        if (selectedtestType === 'long_answer' || selectedtestType === 'short_answer') {
            test_Data.question.forEach((item) => {
                console.log(`Array Items  ${item.question}`);
            })
            const questions = test_Data.question.map((q, index) => ({
                question: q.question,
                answer: answers[index] || ''
            }));

            console.log("Questions being sent:", questions);
            const reqbody = {
                questions: questions,
                assessmentDate: assessmentDate,
                assessmentType: selectedtestType,
                subject: selected_subject,
                board: selected_board,
                className: selected_className
            };
            console.log("REQUEST BODY", reqbody);
            let endpoint = "saveAnswer";
            try {
                const ret = await apiCall(endpoint, 'POST', reqbody);
                console.log("RESPONSE", ret);
                alert("Anwers saved Successfully");
            } catch (error) {
                console.error("API call error:", error);
            }
        } else {
            setShowanswer(true);
            let endpoint = "saveProgress";
            const marksObtained = calculateMarksObtained();
            const reqbody = {
                marksObtained: marksObtained,
                assessmentDate: assessmentDate,
                assessmentType: selectedtestType,
                subject: selected_subject,
                board: selected_board,
                className: selected_className,
            };
            try {
                const ret = await apiCall(endpoint, 'POST', reqbody);
                console.log("RESPONSE", ret);
            } catch (error) {
                console.error("API call error:", error);
            }
        }
    };


    const calculateFullMarks = () => {
        let fullMarks = 0;
        if (test_Data && test_Data.question) {
            test_Data.question.forEach((question) => {
                const questionScore = parseInt(question.score, 10) || 0;
                fullMarks += questionScore;
            });
        }
        return fullMarks;
    };

    const calculateMarksObtained = () => {
        let marksObtained = 0;
        if (test_Data && test_Data.question) {
            test_Data.question.forEach((question, questionIndex) => {
                if (question.exam_type === 'mcq') {
                    const selectedOptionIndex = selected_Options[questionIndex];
                    if (question.options[selectedOptionIndex] === question.answer) {
                        marksObtained += parseInt(question.score, 10) || 0;
                    }
                } else if (question.exam_type === 'fill in the blanks') {
                    const filledBlankAnswer = filled_Blanks[questionIndex];
                    if (filledBlankAnswer && filledBlankAnswer.toLowerCase() === question.answer.toLowerCase()) {
                        marksObtained += parseInt(question.score, 10) || 0;
                    }
                }
            });
        }
        return marksObtained;
    };

    const handleCheckboxChange = (questionIndex, optionIndex) => {
        const newSelectedOptions = [...selected_Options];
        newSelectedOptions[questionIndex] = optionIndex;
        setSelectedOptions(newSelectedOptions);
    };

    const handleFillInTheBlanksChange = (questionIndex, value) => {
        const newFilledBlanks = [...filled_Blanks];
        newFilledBlanks[questionIndex] = value;
        setFilledBlanks(newFilledBlanks);
        console.log('Current Filled Blanks State:', newFilledBlanks);
        setAnswers(newFilledBlanks);
    };

    const handleViewCourses = async () => {
        console.log("CLASS>>>>>>>>", selected_className)
        if (!selected_className && !selected_board && !selected_subject && !selected_fileType && !selected_chapter) {
            setErrorMessage('Fill up the fields to complete your assessment.');
            return;
        }

        if (!selected_className) {
            setErrorMessage('Class is required.');
            return;
        }
        if (!selected_board) {
            setErrorMessage('Board is required.');
            return;
        }
        if (!selected_subject) {
            setErrorMessage('Subject is required.');
            return;
        }
        if (!selected_fileType) {
            setErrorMessage('Assesment Type is required.');
            return;
        }
        if (!selected_chapter) {
            setErrorMessage('Chapter is required.');
            return;
        }
        // Only check selectedtestType if file type is "Test"
        if (selected_fileType === 'Test' && !selectedtestType) {
            setErrorMessage('Test Type is required when File Type is Test.');
            return;
        }
        // Clear error message if all fields are valid
        setErrorMessage('');
        try {
            let endpoint = `search/${selected_fileType}/${selected_board}/${selected_className}/${selected_subject}/${selected_chapter}`;
            if (selected_fileType === "Test" && selectedtestType) {
                endpoint += `/${selectedtestType}`;
            }
            const result = await apiCall(endpoint, 'GET');
            console.log("RESULT>>>>>", result)
            if (result.success) {
                console.log("API Result:", result);

                if (selected_fileType === "Lecture") {
                    setCourseData(result.lecture);
                    setShowCourseDetails(true);
                    setShowTestDetails(false);
                } else if (selected_fileType === "Test") {
                    setTestData(result.test.testData);
                    setShowTestDetails(true);
                    setShowCourseDetails(false);
                }
                //console.log("Questions:", result.test.testData.question);
            } else {
                // For other errors, show the error message
                console.log('API request unsuccessful:', result);
                //alert(result.error);
                setErrorMessage(result.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Error fetching data, please try again later.');
        }
    };
    const FormBox = () => (
        <div className={styles.formBoxLearn}>
            <div className={styles.dropContainer}>
                <Board value={selected_board} onChange={(e) => setSelectedboard(e.target.value)} />
            </div>

            {selected_board && (
                <div className={styles.dropContainer}>
                    <ClassDropdown value={selected_className} onChange={(e) => setSelectedclassName(e.target.value)} />
                </div>
            )}

            {selected_className && (
                <div className={styles.dropContainer}>
                    <Assesment value={selected_fileType} onChange={(e) => setSelectedfiletype(e.target.value)} />
                </div>
            )}

            {selected_fileType && selected_className && (
                <div className={styles.dropContainer}>
                    <FilteredSubjects
                        value={selected_subject}
                        onChange={(e) => setSelectedsubject(e.target.value)}
                        selectedBoard={selected_board}
                        selectedClassName={selected_className}
                        selectedAssesmentType={selected_fileType}
                    />
                </div>
            )}
            {selected_fileType === 'Test' && selected_subject && (
                <div className={styles.dropContainer}>
                    <Test value={selectedtestType} onChange={(e) => setSelectedtestType(e.target.value)} />
                </div>
            )}

            {selected_subject && (
                <div className={styles.dropContainer}>
                    <ChapterDemo
                        value={selected_chapter}
                        onChange={(e) => setSelectedchapter(e.target.value)}
                        selectedBoard={selected_board}
                        selectedClassName={selected_className}
                        selectedSubject={selected_subject}
                        selectedTestType={selectedtestType}
                        selectedAssesmentType={selected_fileType}
                    />
                </div>
            )}           
        </div>
    );



    return (
        <GeneralLayout>

        <Banner />
            <div className={styles.headText}><IoBookSharp />---Please select values form dropdown to give assessment---<IoBookSharp /></div>
            <div className={styles.learnContainer}>
                <div className={styles.formSection}>
                    {errorMessage && (
                        <div className={styles.errorMessageContainer}>
                            <p className={styles.errorMessage}>{errorMessage}</p>
                        </div>
                    )}
                    <FormBox />
                    {/* {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>} */}
                    <button className={styles.buttonLearnPage} onClick={handleViewCourses}>
                        {selected_fileType === 'Test' ? 'Take Assessment' : selected_fileType === 'Lecture' ? 'Watch Video' : 'View'}
                    </button>
                </div>

                <div className={styles.contentSection}>
                    {!show_course_details && !show_test_details ? (
                        <div className={styles.defaultMessage}>
                            <p>Your Video URLs Or Assessments will be visible here</p>
                        </div>
                    ) : (
                        <>
                            {show_course_details && course_Data && <LectureData courseData={course_Data} />}
                            {show_test_details && test_Data && (
                                <>
                                    <TestDetails
                                        show_test_details={show_test_details}
                                        testData={test_Data}
                                        calculateFullMarks={calculateFullMarks}
                                        calculateMarksObtained={calculateMarksObtained}
                                        showanswer={show_answer}
                                        selectedOptions={selected_Options}
                                        filledBlanks={filled_Blanks}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleFillInTheBlanksChange={handleFillInTheBlanksChange}
                                    />
                                    <button className={styles.buttonLearnPage} onClick={handleSubmit}>Submit</button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </GeneralLayout>
    );
}

export default Learnhere;
