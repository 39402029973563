import React, { useState, useEffect } from "react";
import styles from '../../Styles/Dropdown.module.css';
import { apiCall } from '../../utils/apiCall'; 

function Assesment({ value, onChange }) {
  const [assessmentOptions, setAssesmentOptions] = useState([]);

  useEffect(() => {
    const fetchAssesmentOptions = async () => {
      try {
        const response = await apiCall('assessments','GET');
        setAssesmentOptions(response.assessments);
      } catch (error) {
        console.error('Error fetching assessments options:', error);
      }
    };

    fetchAssesmentOptions();
  }, []);

  return (
    <select className={styles.regfields} value={value} onChange={onChange}>
      <option value="">Select Assesment Type</option>
      {assessmentOptions.map((option, index) => (
        <option key={index} className={styles.class} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Assesment;