import React, { useState, useEffect } from 'react';
import styles from '../../Styles/Modal.module.css';
import { RxCross2 } from "react-icons/rx";

const Modal = ({ isOpen, onClose, data, handleSave }) => {
    const [marks, setMarks] = useState(data.map(() => ""));
    const [totalMarks, setTotalMarks] = useState(0);

    useEffect(() => {
        calculateTotalMarks();
    }, [marks]);

    const handleMarksChange = (index, value) => {
        const newMarks = [...marks];
        newMarks[index] = value;
        setMarks(newMarks);
    };

    const calculateTotalMarks = () => {
        const total = marks.reduce((sum, mark) => sum + (parseFloat(mark) || 0), 0);
        setTotalMarks(total);
    };

    const onSave = () => {
        handleSave(totalMarks);
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <RxCross2 className={styles.closeButton} onClick={onClose} />
                <h2>Details</h2>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <div className={styles.tableCell}>Question</div>
                        <div className={styles.tableCell}>Answer</div>
                        <div className={styles.tableCell}>Marks Obtained</div>
                    </div>
                    {data.map((item, index) => (
                        <div className={styles.tableRow} key={index}>
                            <div className={styles.tableCell}>{item.question}</div>
                            <div className={styles.tableCell}>{item.answer}</div>
                            <div className={styles.tableCell}>
                                <input
                                    type="number"
                                    value={marks[index]}
                                    onChange={(e) => handleMarksChange(index, e.target.value)}
                                    className={styles.inputField}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles.totalMarks}>
                    <strong>Total Marks: {totalMarks}</strong>
                </div>
                <button className={styles.button} onClick={onSave}>Save</button>
            </div>
        </div>
    );
};

export default Modal;
