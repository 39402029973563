import React from 'react';
import styles from '../../Styles/LectureData.module.css';
import { GoPaperAirplane } from "react-icons/go";

function LectureData({ courseData }) {
    console.log("Received courseData:", courseData);

    if (!courseData || !courseData.chapters || courseData.chapters.length === 0) {
        return <div>No lectures available.</div>;
    }

    return (
        <div className={styles.course_details_come}>
            <div className={styles.text}>----- Click on the Lecture Url to view the video -----</div>

            {/* Map over the chapters */}
            {courseData.chapters.map((chapter, chapterIndex) => (
                <div key={chapterIndex}>
                    <div className={styles.object}>
                        <p className={styles.keys}>
                            <GoPaperAirplane className={styles.logo} /> Chapter Name : {chapter.chapter_name} (Chapter {chapter.chapter_number})
                        </p>
                    </div>
                    <div className={styles.object}>
                        <p className={styles.keys}>
                            <GoPaperAirplane className={styles.logo} /> Subject : {courseData.subject_name}
                        </p>
                    </div>

                    {/* Map over the lecture array within each chapter */}
                    <div className={styles.container}>
                        {chapter.lecture.map((lecture, lectureIndex) => (
                            <div className={styles.objects} key={lectureIndex}>
                                <p className={styles.head_text}>
                                    <GoPaperAirplane className={styles.logo} /> Lecture
                                </p>
                                <div className={styles.lecture_object}>
                                    <p className={styles.keys}>Lecture Title : {lecture.title}</p>
                                </div>
                                <div style={{ display: "flex", gap: "5px" }}>
                                    <div className={styles.lecture_object}>Lecture URL:</div>
                                    <a
                                        href={lecture.url}
                                        className={styles.value}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const iframe = document.createElement('iframe');
                                            iframe.width = '560';
                                            iframe.height = '315';
                                            iframe.src = lecture.url.replace('watch?v=', 'embed/');
                                            iframe.allowFullScreen = true;

                                            e.target.parentNode.appendChild(iframe);
                                            e.target.remove();
                                            iframe.requestFullscreen().catch((error) => {
                                                console.error('Error entering fullscreen:', error);
                                            });
                                        }}
                                    >
                                        {lecture.url}
                                    </a>
                                </div>
                                <div className={styles.lecture_object}>
                                    <p className={styles.keys}>Views : {lecture.views}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
            ))}
                </div>
            );
}

            export default LectureData;
