import React, { useState, useRef, useEffect } from 'react';
import { IoIosSave } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { GoPaperAirplane } from "react-icons/go";
import { apiCall } from '../../utils/apiCall';
import { RxCross2 } from "react-icons/rx";
import styles from '../../Styles/CreateLecture.module.css';
import { Board, Subject, ClassDropdown, Chapter } from "../Dropdowns/index";

export default function CreateLecture() {
    const [selected_board, setSelectedboard] = useState('');
    const [selected_className, setSelectedclassName] = useState('');
    const [selected_subject, setSelectedsubject] = useState('');
    const [selected_chapter, setSelectedchapter] = useState('');
    const [chapterName, setChapterName] = useState('');
    const [cards, setCards] = useState([{ chapterName: '', lectureTitle: '', views: '', channel: '' }]);

    const [errors, setErrors] = useState({
        board: '',
        className: '',
        subject: '',
        chapter: '',
        chapterName: '',
        cardFields: []
    });

    const cardContainerRef = useRef(null);

    const addCard = () => {
        setCards([...cards, { chapterName: '', lectureTitle: '', views: '', channel: '' }]);
    };

    useEffect(() => {
        if (cardContainerRef.current) {
            cardContainerRef.current.scrollTop = cardContainerRef.current.scrollHeight;
        }
    }, [cards]);

    const removeCard = (index) => {
        const updatedCards = cards.filter((_, cardIndex) => cardIndex !== index);
        setCards(updatedCards);
    };

    const handleInputChange = (index, e) => {
        const updatedCards = cards.map((card, cardIndex) =>
            cardIndex === index ? { ...card, [e.target.name]: e.target.value } : card
        );
        setCards(updatedCards);
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            board: '',
            className: '',
            subject: '',
            chapter: '',
            chapterName: '',
            cardFields: []
        };

        if (!selected_board) {
            newErrors.board = 'Board is required';
            valid = false;
        }

        if (!selected_className) {
            newErrors.className = 'Class is required';
            valid = false;
        }

        if (!selected_subject) {
            newErrors.subject = 'Subject is required';
            valid = false;
        }

        if (!selected_chapter) {
            newErrors.chapter = 'Chapter is required';
            valid = false;
        }

        if (!chapterName) {
            newErrors.chapterName = 'Chapter Name is required';
            valid = false;
        }

        // Validate each card
        const updatedCardErrors = cards.map((card, index) => {
            const cardErrors = [];
            if (!card.lectureTitle) cardErrors.push('Lecture Title is required');
            if (!card.channel) cardErrors.push('Channel is required');
            if (!card.views) cardErrors.push('Views are required');
            if (!card.url) cardErrors.push('URL is required');
            return cardErrors;
        });

        // Add card errors if any
        updatedCardErrors.forEach(errors => {
            if (errors.length > 0) valid = false;
        });

        newErrors.cardFields = updatedCardErrors;

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return; // Validation failed, don't proceed with the submission
        }

        const lectureData = {
            board: selected_board,
            grade: selected_className,
            subject_name: selected_subject,
            alt_subject_name: chapterName,
            chapters: [
                {
                    chapter_number: selected_chapter,
                    chapter_name: chapterName,
                    lecture: cards.map(card => ({
                        title: card.lectureTitle,
                        url: card.url,
                        channel: card.channel,
                        views: card.views
                    }))
                }
            ]
        };

        const binfile = btoa(JSON.stringify(lectureData));
        const payload = {
            selected_board,
            selected_class_name: selected_className,
            selected_subject,
            file_type: 'Lecture',
            base64_file: binfile,
        };

        try {
            const result = await apiCall('lecture/create', 'POST', payload);

            if (result.success) {
                alert('Lecture created successfully');
            } else {
                alert(`Error: ${result.message}`);
            }
        } catch (error) {
            console.error('Error submitting lecture:', error);
            alert('Failed to submit the lecture.');
        }
    };

    return (
        <div className={styles.formContainer}>
            <div className={styles.dropdownContainer}>
                <div className={styles.drop_container}>
                    <Board value={selected_board} onChange={(e) => setSelectedboard(e.target.value)} />
                    {errors.board && <p className={styles.errorText}>{errors.board}</p>}
                </div>
                <div className={styles.drop_container}>
                    <ClassDropdown value={selected_className} onChange={(e) => setSelectedclassName(e.target.value)} />
                    {errors.className && <p className={styles.errorText}>{errors.className}</p>}
                </div>
                <div className={styles.drop_container}>
                    <Subject value={selected_subject} onChange={(e) => setSelectedsubject(e.target.value)} />
                    {errors.subject && <p className={styles.errorText}>{errors.subject}</p>}
                </div>
                <div className={styles.drop_container}>
                    <Chapter value={selected_chapter} onChange={(e) => setSelectedchapter(e.target.value)} />
                    {errors.chapter && <p className={styles.errorText}>{errors.chapter}</p>}
                </div>
            </div>

            <input
                type="text"
                name="chapterName"
                value={chapterName}
                placeholder="Chapter Name"
                className={styles.inputField}
                onChange={(e) => setChapterName(e.target.value)} 
            />
            {errors.chapterName && <p className={styles.errorText}>{errors.chapterName}</p>}

            <div className={styles.cardContainer} ref={cardContainerRef}>
                {cards.map((card, index) => (
                    <div key={index} className={styles.card}>
                        <div className={styles.headerContainer}>
                            <p className={styles.head_text}>
                                <GoPaperAirplane className={styles.logo} /> Lecture
                            </p>
                            <button className={styles.removeButton} onClick={() => removeCard(index)}>
                                <RxCross2 />
                            </button>
                        </div>
                        <input
                            type="text"
                            name="lectureTitle"
                            value={card.lectureTitle}
                            placeholder="Lecture Title"
                            className={styles.inputField}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        {errors.cardFields[index] && errors.cardFields[index].includes('Lecture Title is required') && (
                            <p className={styles.errorText}>Lecture Title is required</p>
                        )}

                        <input
                            type="text"
                            name="url"
                            value={card.url}
                            placeholder="Lecture URL"
                            className={styles.inputField}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        {errors.cardFields[index] && errors.cardFields[index].includes('URL is required') && (
                            <p className={styles.errorText}>URL is required</p>
                        )}

                        <input
                            type="text"
                            name="channel"
                            value={card.channel}
                            placeholder="Channel Name"
                            className={styles.inputField}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        {errors.cardFields[index] && errors.cardFields[index].includes('Channel is required') && (
                            <p className={styles.errorText}>Channel is required</p>
                        )}

                        <input
                            type="number"
                            name="views"
                            value={card.views}
                            placeholder="Views"
                            className={styles.inputField}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        {errors.cardFields[index] && errors.cardFields[index].includes('Views are required') && (
                            <p className={styles.errorText}>Views are required</p>
                        )}
                    </div>
                ))}
            </div>

            <div className={styles.buttonContainer}>
                <button className={styles.addButton} onClick={addCard}>
                    Add More <MdAdd />
                </button>
                <button className={styles.saveButton} onClick={handleSubmit}>
                    Submit <IoIosSave />
                </button>
            </div>
        </div>
    );
}
