import React from "react";
import { FaTimes, FaBook, FaChalkboardTeacher, FaQuestionCircle } from "react-icons/fa";
import styles from "../../Styles/Modal.module.css";

const Modal = ({ show, onClose, testData }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <FaTimes />
        </button>
        <h3 className={styles.modalTitle}>Test Details</h3>
        {testData && testData.binfile && (
          <div className={styles.testDetails}>
            <p><FaBook /> <strong>Chapter Name:</strong> {testData.binfile.chapter_name}</p>
            <p><FaBook /> <strong>Board:</strong> {testData.board}</p>
            <p><FaBook /> <strong>Class:</strong> {testData.className}</p>
            <p><FaChalkboardTeacher /> <strong>Subject:</strong> {testData.subject}</p>
            <p><FaBook /> <strong>Chapter:</strong> {testData.chapter}</p>
            <p><FaQuestionCircle /> <strong>Test Type:</strong> {testData.testType}</p>

            <div className={styles.questionsContainer}>
              <h4>Questions</h4>
              {testData.binfile.question.map((question, index) => (
                <div key={index} className={styles.questionCard}>
                  <p><strong>Q{question.question_number}:</strong> {question.question}</p>

                  {question.exam_type === 'mcq' && (
                    <>
                      <p><strong>Answer:</strong> {question.answer}</p>
                      <p><strong>Marks:</strong> {question.score}</p>
                      <p><strong>Options:</strong></p>
                      <ul>
                        {question.options.map((option, idx) => (
                          <li key={idx}>{option}</li>
                        ))}
                      </ul>
                    </>
                  )}

                  {question.exam_type === 'fill in the blanks' && (
                    <>
                      <p><strong>Marks:</strong> {question.score}</p>
                      <p><strong>Answer:</strong> {question.answer}</p>
                    </>
                  )}

                  {question.exam_type === 'short question' && (
                    <>
                      <p><strong>Marks:</strong> {question.score}</p>
                      <p><strong>Answer:</strong> {question.answer}</p>
                    </>
                  )}

                  {question.exam_type === 'long answer' && (
                    <>
                      <p><strong>Marks:</strong> {question.score}</p>
                      <p><strong>Answer:</strong> {question.answer}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
