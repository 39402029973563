

export async function apiCallAdmin(endpoint, method = null, body = null) {
    try {
        const BASE_URL = process.env.REACT_APP_ALM_API_URL;
        const url = `${BASE_URL}/${endpoint}`;
        console.log("SLKNakjnkajbdakjbkadjbkadfbdkjbksbdksaj",url)
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('admintoken'),
            },
            body: body ? JSON.stringify(body) : null,
        };
        const response = await fetch(url, options);
        const data = await response.json(); 

        if (!response.ok) {
            // Handle error response
            return { success: false, message: data.message || 'Failed to fetch data' };
        }

        return { success: true, ...data }; 
    } catch (error) {
        console.error('API Error:', error);
        return { success: false, message: 'API Error occurred' }; 
    }
}
