import React, { useState, useEffect } from "react";
import styles from '../../Styles/Dropdown.module.css';
import { apiCall } from '../../utils/apiCall';

function Board({ value, onChange }) {
  const [boardOptions, setBoardOptions] = useState([]);

  useEffect(() => {
    const fetchBoardOptions = async () => {
      try {
        const response = await apiCall('board','GET');
        setBoardOptions(response.boards);
      } catch (error) {
        console.error('Error fetching board options:', error);
      }
    };

    fetchBoardOptions();
  }, []);

  return (
    <select className={styles.regfields} value={value} onChange={onChange}>
      <option value="" >Select Board</option>
      {boardOptions.map((option, index) => (
        <option key={index} className={styles.class} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Board;