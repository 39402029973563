import React, { useState } from 'react';
import AdminLayout from '../../Layouts/AdminLayout';
import CreateTest from '../../components/Admin/CreateTest';
import CreateLecture from '../../components/Admin/CreateLecture'; // Corrected import for CreateLecture
import styles from "../../Styles/CreateAssesment.module.css";
import { Assesment } from "../../components/Dropdowns/index";

export default function CreateAssesment() {
    const [selected_fileType, setSelectedfiletype] = useState("");
    const [showCreateLecture, setShowCreateLecture] = useState(false); 
    const [showCreateTest, setShowCreateTest] = useState(false);  // State for CreateTest

    const handleCreateClick = () => {
        if (selected_fileType === "Lecture") {
            setShowCreateLecture(true);
            setShowCreateTest(false); // Hide CreateTest when selecting Lecture
        } else if (selected_fileType === "Test") {
            setShowCreateTest(true);
            setShowCreateLecture(false); // Hide CreateLecture when selecting Test
        } else {
            alert('Please select the Assessment type to create a Lecture or Test.');
        }
    };

    return (
        <AdminLayout>
            <div className={styles.container}>
                <div className={styles.drop_container}>
                    <Assesment value={selected_fileType} onChange={(e) => setSelectedfiletype(e.target.value)} />
                </div>
                <button className={styles.createButton} onClick={handleCreateClick}>
                    Create
                </button>
            </div>

            {showCreateLecture && <CreateLecture />} 
            {showCreateTest && <CreateTest />}  
        </AdminLayout>
    );
}
