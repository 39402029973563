import React, { useState, useEffect } from "react";
import styles from '../../Styles/Dropdown.module.css';
import { apiCall } from "../../utils/apiCall";

function ClassDropdown({ value, onChange }) {
  const [classOptions, setClassOptions] = useState([]);

  useEffect(() => {
    // Fetch class options from the backend API
    const fetchClassOptions = async () => {
      try {
        const response = await apiCall('classes','GET');
        setClassOptions(response.classes);
      } catch (error) {
        console.error('Error fetching class options:', error);
      }
    };

    fetchClassOptions();
  }, []);

  return (
    <select className={styles.regfields} value={value} onChange={onChange}>
      <option value=""> Select Class</option>
      {classOptions.map((option, index) => (
        <option key={index} className={styles.class} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default ClassDropdown;