import React, { useState } from 'react';
import styles from '../../Styles/ResetPasswordForm.module.css';
import { RiMailFill } from 'react-icons/ri';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { apiCall } from '../../utils/apiCallTeacher';
import { useNavigate } from "react-router-dom";
export default function ResetPasswordForm() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [oldpassword, setOldPassword] = useState('');
    const [createpassword, setCreatePassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'oldpassword') {
            setOldPassword(value);
        } else if (name === 'createpassword') {
            setCreatePassword(value);
        }
    };

    const handleSignIn = async () => {
        const payload = {
                email: email,
                oldPassword: oldpassword,
                newPassword: createpassword,
                is_active: true,
        }
        try {
            const response = await apiCall('updatePassword', 'POST',payload);

            if (response.success) {
                alert(response.message);
                navigate('/TeacherLogin');
            } else {
                alert('Error: ' + response.message);
            }
        } catch (error) {
            console.error('Error updating password:', error);
            alert('An error occurred while updating the password.');
        }
    };

    return (
        <div className={styles.form_box}>
            <div className={styles.input_wrapper}>
                <input
                    type="text"
                    name="email"
                    value={email}
                    className={styles.fields}
                    placeholder="Email"
                    onChange={handleInputChange}
                />
                <RiMailFill />
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type={showPassword ? 'text' : 'password'}
                    name="oldpassword"
                    value={oldpassword}
                    className={styles.fields}
                    placeholder="Old Password"
                    onChange={handleInputChange}
                />
                {showPassword ? (
                    <FaEyeSlash className={styles.icon} onClick={togglePasswordVisibility} />
                ) : (
                    <FaEye className={styles.icon} onClick={togglePasswordVisibility} />
                )}
            </div>
            <div className={styles.input_wrapper}>
                <input
                    type={showPassword ? 'text' : 'password'}
                    name="createpassword"
                    value={createpassword}
                    className={styles.fields}
                    placeholder="New Password"
                    onChange={handleInputChange}
                />
                {showPassword ? (
                    <FaEyeSlash className={styles.icon} onClick={togglePasswordVisibility} />
                ) : (
                    <FaEye className={styles.icon} onClick={togglePasswordVisibility} />
                )}
            </div>
            <button className={styles.button} onClick={handleSignIn}>
                Reset Password
            </button>
        </div>
    );
}
