import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCall } from '../../utils/apiCall';
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
import GeneralLayout from "../../Layouts/GeneralLayout";
import styles from '../../Styles/Registration.module.css';
import SubjectCard from "../../components/Registration/SubjectCard";
import PersonalInfoCard from "../../components/Registration/PersonalInfoCard";

function Registration() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [form_Data, setFormData] = useState({
        fullName: "",
        age: "",
        phoneNo: "",
        email: "",
        schoolName: "",
        studentClass: "",
        board: "",
        subjects: [
            { subjectName: "", bookName: "", author: "" },
        ],
    });

    const addMoreSubjects = () => {
        setFormData((prevData) => ({
            ...prevData,
            subjects: [...prevData.subjects, { subjectName: "", bookName: "", author: "" }],
        }));
    };

    const handleInputChangedt = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const subjects = [...form_Data.subjects];
        subjects[index][name.split(".")[1]] = value;
        setFormData((prevData) => ({ ...prevData, subjects }));
    };

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const handlePrev = () => {
        setStep((prevStep) => Math.max(prevStep - 1, 1));
    };

    const handleRegister = async () => {
        if (!form_Data.fullName || !form_Data.age || !form_Data.phoneNo || !form_Data.schoolName || !form_Data.email || form_Data.subjects.some(subject => !subject.subjectName || !subject.bookName || !subject.author)) {
            alert("All fields are mandatory");
            return;
        }
        try {
            const endpoint = 'saveStudentDetails';
            const result = await apiCall(endpoint, 'POST', form_Data);
            if (result.success) {
                alert("Successfully Registered");
                navigate('/Learn');
            } else {
                alert("All fields are mandatory");
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    const indexFunction = () => {
        return (
            <div className={styles.subject_card_container}>
                {form_Data.subjects.map((subject, index) => (
                    <SubjectCard
                        key={index}
                        subject={subject}
                        arr_index={index}
                        handleInputChange={handleInputChange}
                    />
                ))}
            </div>
        );
    };

    const ButtonIconContainer = () => {
        return (
            <div className={styles.prog_icon_container}>
                <FaAngleDoubleLeft title="Previous" onClick={handlePrev} className={styles.prog_icon} />
                {step === 1 && (
                    <button className={styles.procedebutton} onClick={handleNext}>Next</button>
                )}
                {step === 2 && (
                    <div className={styles.container}>
                        <button className={styles.procedebutton} onClick={handleRegister}>Register</button>
                        <button className={styles.addMoreButton} onClick={addMoreSubjects}>Add More Subjects +</button>
                    </div>
                    
                )}
                <FaAngleDoubleRight title="Next" onClick={handleNext} className={styles.prog_icon} />
            </div>
        );
    };

    return (
        <GeneralLayout>
            <div className={styles.dataform}>
                <div className={styles.crad}>
                    {step === 1 && (
                        <PersonalInfoCard formData={form_Data} handleInputChangedt={handleInputChangedt} />
                    )}
                </div>
                {step >= 2 && (
                    <>
                        {indexFunction()}
                    </>
                )}
                <ButtonIconContainer />
            </div>
        </GeneralLayout>
    );
}

export default Registration;
