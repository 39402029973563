import React from 'react'
import styles from '../../Styles/ResetPassword.module.css';
import ResetPasswordForm from '../../components/Teacher/ResetPasswordForm';

export default function ResetPassword() {
  return (
    <div className={styles.main}>
        <ResetPasswordForm />
    </div>
  )
}
