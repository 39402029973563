import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRouteTeacher = ({ element, ...props }) => {
    const teachertoken = localStorage.getItem('teachertoken');

    if (!teachertoken) {
        alert("Please log in to continue.");
        return <Navigate to="/TeacherLogin" />;
    }

    return React.cloneElement(element, props);
};

export default PrivateRouteTeacher;
