import React, { useState, useEffect } from "react";
import styles from '../Styles/Home.module.css';
import Navbar from "../components/common/Navbar";
import img1 from '../images/25.jpg';
import { useNavigate } from "react-router-dom";
import img2 from '../images/26.jpg';
import img3 from '../images/03.jpg';

function Home() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);
    const images = [img1,img1];
    const navigate = useNavigate();
    useEffect(() => {
        let interval;
        if (isAutoPlaying) {
            interval = setInterval(() => {
                nextSlide();
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [currentIndex, isAutoPlaying]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'ArrowLeft') {
                prevSlide();
            } else if (event.key === 'ArrowRight') {
                nextSlide();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [currentIndex]);

    const prevSlide = () => {
        setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
    };

    const nextSlide = () => {
        setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className={styles.pageContainer}>
            <div className={styles.navbarWrapper}>
                <Navbar />
            </div>
            <div className={styles.banner}>
                <img src={images[currentIndex]} alt="Banner" className={styles.bannerImage} />
                <div className={styles.bannerOverlay}>
                    <button className={styles.ctaButton} onClick={() => handleNavigation("/about")}>
                         Learn More
                    </button>
                </div>
            </div>
            <div className={styles.scrollableContent}>
                <main className={styles.main}>
                    <div className={styles.header}>
                        <h1 className={styles.title}>Alif-Lam-Mim Mission</h1>
                        <div className={styles.underline}></div>
                    </div>

                    <div className={styles.contentWrapper}>
                    <section className={styles.missionSection}>
                            <h2 className={styles.sectionTitle}>Our Sacred Mission</h2>
                            <div className={styles.text}>
                                Alif-Lam-Mim Mission's (ALM) sacred mission is underpinned by unwavering devotion and a profound sense of duty to uplift the hearts and minds of the young scholars,
                                nurturing them as torchbearers of knowledge and faith. Alif-Lam-Mim is not a conventional school or a Madrasa; rather, it's a residential home for underprivileged young students.
                            </div>
                            
                            <div className={styles.text}>
                                ALM aspires to foster an environment where their dreams can take flight, where they can transcend the limitations of their circumstances, and where they can emerge as exemplars of faith,
                                intellect, and virtue. In this endeavor, we humbly seek the support and blessings of the Almighty, recognizing that our journey towards enlightenment is inseparable from His divine will
                                and guidance.
                            </div>

                            <div className={`${styles.text} ${styles.highlight}`}>
                                With steadfast determination, we pledge to illuminate the path of these young souls, instilling in them the eternal values of our faith as they embark on their academic journeys.
                                Together, guided by the divine light, we shall strive to empower a generation of young scholars, fostering a brighter future for our nation and the global community.
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Home;