import React, { useState } from 'react';
import styles from '../../Styles/TeacherDashboard.module.css';
import { ClassDropdown, Board, Subject } from "../../components/Dropdowns/index";
import { apiCall } from '../../utils/apiCallTeacher';
import { AssementTable } from '../../components/Admin/index';
import Modal from '../../components/Teacher/AnswerModal';
import TeacherLayout from '../../Layouts/TeacherLayout'

export default function TeacherDashboard() {
    const [selected_board, setSelectedboard] = useState("");
    const [selected_className, setSelectedclassName] = useState("");
    const [selected_subject, setSelectedsubject] = useState("");
    const [data, setData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalMarks, setTotalMarks] = useState(0);

    const columns = [
        {
            header: 'Sl.NO',
            accessorKey: 'id',
        },
        {
            header: 'Date',
            accessorKey: 'assessmentDate',
        },
        {
            header: 'Test Type',
            accessorKey: 'assessmentType',
        },
        {
            header: 'Subject',
            accessorKey: 'subject',
        },
        {
            header: 'Board',
            accessorKey: 'board',
        },
        {
            header: 'Class',
            accessorKey: 'className',
        },
        {
            header: 'Email',
            accessorKey: 'email',
        },
        {
            header: 'Actions',
            cell: ({ row }) => (
                <button onClick={() => handleViewClick(row.original.id)}>View</button>
            ),
        },
    ];

    const handelViewCourse = async () => {
        let endpoint = `filterAnswerData/${selected_board}/${selected_className}/${selected_subject}`;
        try {
            const response = await apiCall(endpoint, 'GET');
            console.log("RESPONSE", response);

            if (response.success) {
                if (response.data.data && response.data.data.length > 0) {
                    setData(response.data.data);
                } else {
                    alert('No data received');
                    setData([]);
                }
            } else {
                alert('Failed to fetch data');
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('API call error:', error);
            alert('An error occurred while fetching data. Please try again.');
        }
    };


    const handleViewClick = async (id) => {
        let endpoint = `filterQuestionAnswerData/${id}`;
        const response = await apiCall(endpoint, 'GET');
        if (response.success) {
            const row = data.find(item => item.id === id);
            const additionalData = {
                email: row.email,
                assessmentType: row.assessmentType
            };
            const modalDataWithAdditionalData = response.data.data.map(item => ({
                ...item,
                ...additionalData
            }));
            setModalData(modalDataWithAdditionalData);
            setIsModalOpen(true);
        } else {
            console.error('Failed to fetch data');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalData([]);
    };

    const handleSave = async (totalMarks) => {
        setTotalMarks(totalMarks);
        setIsModalOpen(false);

        if (modalData.length === 0) {
            console.error("No data available to save");
            return;
        }

        const { email, assessmentType } = modalData[0];

        let endpoint = "saveAnswerRecord";
        const assessmentDate = new Date().toISOString().split('T')[0];
        const reqbody = {
            marksObtained: totalMarks,
            assessmentDate: assessmentDate,
            assessmentType: assessmentType,
            subject: selected_subject,
            board: selected_board,
            className: selected_className,
            email: email
        };

        try {
            const ret = await apiCall(endpoint, 'POST', reqbody);
            console.log("RESPONSE", reqbody);
        } catch (error) {
            console.error("API call error:", error);
        }
    };

    return (
        <TeacherLayout>
            <div className={styles.container}>
                <div className={styles.drop_container}>
                    <Board value={selected_board} onChange={(e) => setSelectedboard(e.target.value)} />
                </div>
                <div className={styles.drop_container}>
                    <ClassDropdown value={selected_className} onChange={(e) => setSelectedclassName(e.target.value)} />
                </div>
                <div className={styles.drop_container}>
                    <Subject value={selected_subject} onChange={(e) => setSelectedsubject(e.target.value)} />
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.view} onClick={handelViewCourse}>View</button>
            </div>
            <div className={styles.tableContainer}>
                <AssementTable data={data} columns={columns} />
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} data={modalData} handleSave={handleSave} />
        </TeacherLayout>
    );
}
