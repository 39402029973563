import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css'; // Ensure that KaTeX styles are applied
import styles from '../../Styles/TestDetails.module.css';

function TestDetails({
    show_test_details,
    testData,
    calculateFullMarks,
    calculateMarksObtained,
    showanswer,
    selectedOptions,
    filledBlanks,
    handleCheckboxChange,
    handleFillInTheBlanksChange,
}) {
    return (
        <div className={styles.test_details}>
            <div className={styles.marks}>
                <div className={styles.fullMarks}>Full Marks: {calculateFullMarks()}</div>
                {showanswer && (
                    <div className={styles.marksObtained}>Marks Obtained: {calculateMarksObtained()}</div>
                )}
            </div>
            {show_test_details && testData && testData.question.map((question, questionIndex) => (
                <div key={questionIndex} className={styles.question_container}>
                    {/* MCQ Questions */}
                    {question.exam_type === 'mcq' && (
                        <>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>
                                    <Latex>{question.question}</Latex> {/* Render LaTeX in the question */}
                                </p>
                                <p className={styles.elementsMarks}>Marks: {question.score}</p>
                                {showanswer && (
                                    <p className={styles.correct_elements}>
                                        Correct Answer: <Latex>{question.answer}</Latex> {/* LaTeX for the answer */}
                                    </p>
                                )}
                            </div>
                            <div className={styles.option_element_one}>
                                {question.options.map((option, optionIndex) => (
                                    <div
                                        key={optionIndex}
                                        className={`${styles.option} ${showanswer && selectedOptions[questionIndex] === optionIndex && option !== question.answer ? styles.incorrectAnswer : ''}`}
                                    >
                                        <input
                                            type="checkbox"
                                            className={styles.checkbox}
                                            checked={selectedOptions[questionIndex] === optionIndex}
                                            onChange={() => handleCheckboxChange(questionIndex, optionIndex)}
                                        />
                                        {/* Ensure double backslashes for LaTeX rendering */}
                                        <Latex>{`$${option}$`}</Latex> {/* Render LaTeX in the options */}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {/* Fill in the Blanks Questions */}
                    {question.exam_type === 'fill in the blanks' && (
                        <div>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>
                                    <Latex>{question.question}</Latex> {/* LaTeX for the question */}
                                </p>
                                <p className={styles.elements}>Marks: {question.score}</p>
                                {showanswer && (
                                    <p className={styles.correct_elements}>
                                        Correct Answer: <Latex>{question.answer}</Latex> {/* LaTeX for the answer */}
                                    </p>
                                )}
                            </div>
                            <div className={styles.option_element_one}>
                                <input
                                    type="text"
                                    //className={styles.text}
                                    value={filledBlanks[questionIndex] || ''}
                                    onChange={(e) => handleFillInTheBlanksChange(questionIndex, e.target.value)}
                                    className={`${styles.text_input} ${showanswer && filledBlanks[questionIndex] !== question.answer ? styles.incorrectAnswer : ''}`}
                                />
                            </div>
                        </div>
                    )}

                    {/* Short Answer Questions */}
                    {question.exam_type.toLowerCase().includes('short')&& (
                        <div>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>
                                    <Latex>{question.question}</Latex> {/* LaTeX for the question */}
                                </p>
                                <p className={styles.elements}>Marks: {question.score}</p>
                            </div>
                            <div className={styles.option_element_one}>
                                <textarea
                                    rows="2"
                                    className={styles.textarea}
                                    value={filledBlanks[questionIndex] || ''}
                                    onChange={(e) => handleFillInTheBlanksChange(questionIndex, e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                    {question.exam_type.toLowerCase().includes('long') && (
                        <div>
                            <div className={styles.questions}>
                                <p className={styles.elements}>{questionIndex + 1}.</p>
                                <p className={styles.elements}>
                                    <Latex>{question.question}</Latex> {/* LaTeX for the question */}
                                </p>
                                <p className={styles.elements}>Marks: {question.score}</p>
                            </div>
                            <div className={styles.option_element_one}>
                                <textarea
                                    rows="5"
                                    className={styles.textarea}
                                    value={filledBlanks[questionIndex] || ''}
                                    onChange={(e) => handleFillInTheBlanksChange(questionIndex, e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                </div>
            ))}
        </div>
    );
}

export default TestDetails;
